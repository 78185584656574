import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

const CDH: React.FC = () => {

  const cdhContainer = css`
  width: 1250px;
  height: 1050px;
  border: 5px dashed rgb(33, 110, 224);
  `

  return (
    <div className={cdhContainer}>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:600}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top:400}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <p>IAU</p>

    </div>
  )
}

export default CDH;
