import React from 'react'
import { Handle, Position } from 'reactflow';
import { InnerData } from './utils';
import { css } from '@emotion/css';

interface IAUProps {
  data: {
    value: InnerData;
    name: string;
  };
}

const IAU: React.FC<IAUProps> = ({data}) => {

  const iauContainer = css`
  width: 1150px;
  height: 875px;
  // border: 5px dashed rgb(183, 134, 91);
  // background-color: rgb(183, 134, 91);
  border: 5px dashed rgb(33, 110, 224);
  `


  return (
    <div className={iauContainer}>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Right} id="target-right"/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>
      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <p>IAU</p>

    </div>
  )
}

export default IAU;
