import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, LocationData, ProcessedData } from './utils';
import Panel from '../module/Panel';
import CDH from '../module/CDH';
import GlideCommTB from './Textbox';
import Ground from 'module/Ground';
import Tele from 'module/Tele';
import Antennas from './Antennas';
import MOC from 'module/MOC';
import CustomEdge from './CustomEdge';
import GlideCommSBT from 'components/GlideCommSBT';
import BarChartComponent from './Bar';
import ModelViewer from './SCObj';
import IAU from './IAU';
import { css } from '@emotion/css';
import ScSketch from './ScSketch';
import VModeBox from './VModeBox';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import { useGroundData, useTelemetryData } from 'hooks/DataProcessing';
import PassIndicator from './PassIndicator';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

type AppProps = {
  dbData: Field[];
  groundData: Field[];
  source: string;
  locationData: LocationData;
  width: number;
  height: number;
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are any s s
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-eidpu, .react-flow__node-modelViewer{
  z-index: 6 !important;
}

.react-flow__node-panel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

const nodeTypes = { iau: IAU, modelViewer: ModelViewer, passIndicator:PassIndicator,scSketch: ScSketch, vmodeBox: VModeBox, ground: Ground, bar: BarChartComponent, glideCommSBT: GlideCommSBT, panel: Panel, antennas: Antennas, tele: Tele, cdh: CDH, glideCommTB: GlideCommTB, moc: MOC }
const edgeTypes = { customEdge: CustomEdge }

const GlideCommApp: React.FC<AppProps> = ({ dbData, groundData, source, locationData, width, height, influxData, limitData }) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  const { createTelemetryDictionary } = useTelemetryData(dbData, limitData);
  const { getGroundData } = useGroundData(groundData);

  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView();
    }
  }
    , [width, height, reactFlowInstance]);


  const groundList = getGroundData();

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'panel', position: { x: 0, y: 50 }, data: {} },
    { id: 'mv1', type: 'modelViewer', parentNode: 'Panel', position: { x: 2650, y: 950 }, data: {'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

    { id: 'Ground Subsystem', type: 'ground', parentNode: 'Panel', position: { x: 1500, y: 1400 }, data: {} },
    { id: 'Antennas', type: 'antennas', parentNode: 'Ground Subsystem', position: { x: 100, y: 100 }, data: { name: '', 'info': groundList, 'linfo': locationData } },

    { id: 'MOC', type: 'moc', parentNode: 'Panel', position: { x: 50, y: 1400 }, data: {} },
    { id: 'VR2', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 50 }, data: { x1: 17, x2: 30, x3: 100, name: "VR2" } },
    { id: 'VR3', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 250 }, data: { x1: 19, x2: 32, x3: 100, name: "VR3" } },
    { id: 'VR4', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 450 }, data: { x1: 23, x2: 37, x3: 100, name: "VR4" } },

    { id: 'CDH Subsystem', type: 'cdh', parentNode: 'Panel', position: { x: 50, y: 50 }, data: {} },
    { id: 'Tele Subsystem', type: 'tele', parentNode: 'Panel', position: { x: 1450, y: 50 }, data: {} },

    { id: 'S-Band Transponder', type: 'glideCommSBT', parentNode: 'Tele Subsystem', position: { x: 50, y: 300 }, data: { power: telemetryDictionary['Transponder PRI Pwr'], power1: telemetryDictionary['Transponder RED Pwr'], className: 'sbContainer', value: {} } },
    { id: 'Receiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 1250, y: 50 }, data: { name: 'Receiver', className: 'rxContainer', value: { 'Op Mode': { 'dbData': telemetryDictionary['Rx Mode'], 'fname': 'Receiver Operating Mode', 'mname': 'rxopmodest' }, 'Cmd Decrypt': { 'dbData': telemetryDictionary['Command Decrypt'], 'fname': 'Command Decrypt' }, 'Cmd Auth Fail': { 'dbData': telemetryDictionary['Command Auth Fail'], 'fname': 'Command Authentication Fail Count' }, 'TE-Key Select': { 'dbData': telemetryDictionary['TE-Key Selected'] } } } },
    { id: 'Transceiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 600, y: 50 }, data: { name: 'Transceiver', className: 'tcContainer', value: { 'TX Group Plug': { 'dbData': telemetryDictionary['TX Installed'], 'fname': 'TX Group ENBL PLUG INSTALLED' }, 'Pwr Amp Temp': { 'dbData': telemetryDictionary['Pwr Amp Temp'], 'fname': '' }, 'Mod Temp': { 'dbData': telemetryDictionary['Mod Temp'], 'fname': '' }, 'Init Rout Status': { 'dbData': telemetryDictionary['Init Rout Status'], 'fname': '' }, 'Init Rout Result': { 'dbData': telemetryDictionary['Init Rout Result'], 'fname': '' }, 'Init Result': { 'dbData': telemetryDictionary['Init Result'], 'fname': '' }, 'AD9364 Mach State': { 'dbData': telemetryDictionary['AD9364 En Machine State'], 'fname': '' }, 'Master Reset': { 'dbData': telemetryDictionary['Master Reset'], 'fname': '' }, 'Reset Status': { 'dbData': telemetryDictionary['Reset Status'], 'fname': '' } } } },
    { id: 'Transmitter', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 50, y: 50 }, data: { name: 'Transmitter', power: telemetryDictionary['Transmitter PRI Pwr'], power1: telemetryDictionary['Transmitter RED Pwr'], value: { 'Pwr': { 'dbData': telemetryDictionary['Transmitter Pwr'], 'fname': 'Measurement of the transmitter power' }, '18V/28V': { 'dbData': telemetryDictionary['Transmitter 18/28'], 'fname': 'Transmitter 18V/28V secondary' }, 'Mode': { 'dbData': telemetryDictionary['Transmitter Mode'], 'fname': 'Transmitter Operating Mode' }, 'RF State': { 'dbData': telemetryDictionary['RF Enable'], 'fname': 'Status of RF Enable command' }, 'Fwd Pwr Out': { 'dbData': telemetryDictionary['Transponder Fwd Pwr'], 'fname': 'Narrowband Transponder Fwd Power Out' }, 'Coherency En': { 'dbData': telemetryDictionary['Coherency Enabled'], 'fname': 'Coherency Enabled' }, 'Conv Status': { 'dbData': telemetryDictionary['Conv Status'], 'fname': 'Status of the Tx Conv Code Command' }, 'Nrz Status': { 'dbData': telemetryDictionary['Nrz Status'], 'fname': 'Status of the Tx Nrz Conv On Command' }, } } },
    { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },
    { id: 'ScSketch', type: 'scSketch', parentNode: 'Tele Subsystem', position: { x: 1925, y: 150 }, data: { name: '', lgaSwitch:telemetryDictionary['Tx Switch LGA'],mgaSwitch:telemetryDictionary['Tx Switch MGA']}},

    { id: 'SMOAB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 675, y: 125 }, data: { name: 'Standard Multi-Operation Avionics Board(SMOAB)', value: { 'HW Apt Cnt': { 'dbData': telemetryDictionary['HW Apt Cnt'], 'fname': 'HW SOH VC0 Command accept' }, 'HW Rj Cnt': { 'dbData': telemetryDictionary['HW Rj Cnt'], 'fname': 'HW SOH VC0 Command Reject' }, 'SBE Err Cnt': { 'dbData': telemetryDictionary['SBE Cnt'], 'fname': 'Single Bit Error Count' }, 'TMR Err Cnt': { 'dbData': telemetryDictionary['TMR Cnt'], 'fname': 'Flash TMR Errors Count' }, "UL Rand Status": { 'dbData': telemetryDictionary['Moab Uplink Rand Status'], 'fname': '' }, "DL Rand Status": { 'dbData': telemetryDictionary['Moab Downlink Rand Status'], 'fname': '' }, 'WD ISR Status': { 'dbData': telemetryDictionary['SMOAB WatchDog ISR Task Status'], 'fname': '' }, 'WD MBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog MBE Task Status'], 'fname': '' }, 'WD SBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog SBE Task Status'], 'fname': '' }, 'WD TLM Status': { 'dbData': telemetryDictionary['SMOAB WatchDog TLM Task Status'], 'fname': '' } } } },
    { id: 'SSMB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 500 }, data: { name: 'Solids State Memory Board(SSMB)', className: 'ssmbContainer', value: { 'SCI Read St': { 'dbData': telemetryDictionary['SSR Read Status'], 'fname': 'SCI current read State' }, 'SCI Pbk Time': { 'dbData': telemetryDictionary['SCI Pbk Time'], 'fname': '' }, 'SSOH Read St': { 'dbData': telemetryDictionary['SSOH Read State'], 'fname': 'SSR current read State' }, 'SSOH Pbk Time': { 'dbData': telemetryDictionary['SSOH Pbk Time'], 'fname': '' }, 'WD Status': { 'dbData': telemetryDictionary['SSMB WatchDog Task Status'], 'fname': '' } } } },
    { id: 'RAD750', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 50 }, data: { name: 'Single Board Computer(SBC)', value: { 'FSW Apt Cnt': { 'dbData': telemetryDictionary['FSW Apt Cnt'], 'fname': '' }, 'FSW Rj Cnt': { 'dbData': telemetryDictionary['FSW Rj Cnt'], 'fname': '' }, 'CBM Active': { 'dbData': telemetryDictionary['CBM Active'], 'fname': '' }, 'CSM Status': { 'dbData': telemetryDictionary['CSM Status'], 'fname': '' } } } },

    { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 0, y: -175 }, data: { name: '', playBackValue: { time: pbTimeRef.current, loop: pbLoopRef.current }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4'] } },

  ]);

  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Panel', type: 'panel', position: { x: 0, y: 50 }, data: {} },
      { id: 'mv1', type: 'modelViewer', parentNode: 'Panel', position: { x: 2650, y: 950 }, data: {'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

      { id: 'Ground Subsystem', type: 'ground', parentNode: 'Panel', position: { x: 1500, y: 1400 }, data: {} },
      { id: 'Antennas', type: 'antennas', parentNode: 'Ground Subsystem', position: { x: 100, y: 100 }, data: { name: '', 'info': groundList, 'linfo': locationData } },

      { id: 'MOC', type: 'moc', parentNode: 'Panel', position: { x: 50, y: 1400 }, data: {} },
      { id: 'VR2', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 50 }, data: { x1: 17, x2: 30, x3: 100, name: "VR2" } },
      { id: 'VR3', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 250 }, data: { x1: 19, x2: 32, x3: 100, name: "VR3" } },
      { id: 'VR4', type: 'bar', parentNode: 'MOC', position: { x: 50, y: 450 }, data: { x1: 23, x2: 37, x3: 100, name: "VR4" } },

      { id: 'CDH Subsystem', type: 'cdh', parentNode: 'Panel', position: { x: 50, y: 50 }, data: {} },
      { id: 'Tele Subsystem', type: 'tele', parentNode: 'Panel', position: { x: 1450, y: 50 }, data: {} },

      { id: 'S-Band Transponder', type: 'glideCommSBT', parentNode: 'Tele Subsystem', position: { x: 50, y: 300 }, data: { power: telemetryDictionary['Transponder PRI Pwr'], power1: telemetryDictionary['Transponder RED Pwr'], className: 'sbContainer', value: {} } },
      { id: 'Receiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 1250, y: 50 }, data: { name: 'Receiver', className: 'rxContainer', value: { 'Op Mode': { 'dbData': telemetryDictionary['Rx Mode'], 'fname': 'Receiver Operating Mode', 'mname': 'rxopmodest' }, 'Cmd Decrypt': { 'dbData': telemetryDictionary['Command Decrypt'], 'fname': 'Command Decrypt' }, 'Cmd Auth Fail': { 'dbData': telemetryDictionary['Command Auth Fail'], 'fname': 'Command Authentication Fail Count' }, 'TE-Key Select': { 'dbData': telemetryDictionary['TE-Key Selected'] } } } },
      { id: 'Transceiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 600, y: 50 }, data: { name: 'Transceiver', className: 'tcContainer', value: { 'TX Group Plug': { 'dbData': telemetryDictionary['TX Installed'], 'fname': 'TX Group ENBL PLUG INSTALLED' }, 'Pwr Amp Temp': { 'dbData': telemetryDictionary['Pwr Amp Temp'], 'fname': '' }, 'Mod Temp': { 'dbData': telemetryDictionary['Mod Temp'], 'fname': '' }, 'Init Rout Status': { 'dbData': telemetryDictionary['Init Rout Status'], 'fname': '' }, 'Init Rout Result': { 'dbData': telemetryDictionary['Init Rout Result'], 'fname': '' }, 'Init Result': { 'dbData': telemetryDictionary['Init Result'], 'fname': '' }, 'AD9364 Mach State': { 'dbData': telemetryDictionary['AD9364 En Machine State'], 'fname': '' }, 'Master Reset': { 'dbData': telemetryDictionary['Master Reset'], 'fname': '' }, 'Reset Status': { 'dbData': telemetryDictionary['Reset Status'], 'fname': '' } } } },
      { id: 'Transmitter', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 50, y: 50 }, data: { name: 'Transmitter', power: telemetryDictionary['Transmitter PRI Pwr'], power1: telemetryDictionary['Transmitter RED Pwr'], value: { 'Pwr': { 'dbData': telemetryDictionary['Transmitter Pwr'], 'fname': 'Measurement of the transmitter power' }, '18V/28V': { 'dbData': telemetryDictionary['Transmitter 18/28'], 'fname': 'Transmitter 18V/28V secondary' }, 'Mode': { 'dbData': telemetryDictionary['Transmitter Mode'], 'fname': 'Transmitter Operating Mode' }, 'RF State': { 'dbData': telemetryDictionary['RF Enable'], 'fname': 'Status of RF Enable command' }, 'Fwd Pwr Out': { 'dbData': telemetryDictionary['Transponder Fwd Pwr'], 'fname': 'Narrowband Transponder Fwd Power Out' }, 'Coherency En': { 'dbData': telemetryDictionary['Coherency Enabled'], 'fname': 'Coherency Enabled' }, 'Conv Status': { 'dbData': telemetryDictionary['Conv Status'], 'fname': 'Status of the Tx Conv Code Command' }, 'Nrz Status': { 'dbData': telemetryDictionary['Nrz Status'], 'fname': 'Status of the Tx Nrz Conv On Command' }, } } },
      { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },
      { id: 'ScSketch', type: 'scSketch', parentNode: 'Tele Subsystem', position: { x: 1925, y: 150 }, data: { name: '', lgaSwitch:telemetryDictionary['Tx Switch LGA'],mgaSwitch:telemetryDictionary['Tx Switch MGA']}},

      { id: 'SMOAB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 675, y: 125 }, data: { name: 'Standard Multi-Operation Avionics Board(SMOAB)', value: { 'HW Apt Cnt': { 'dbData': telemetryDictionary['HW Apt Cnt'], 'fname': 'HW SOH VC0 Command accept' }, 'HW Rj Cnt': { 'dbData': telemetryDictionary['HW Rj Cnt'], 'fname': 'HW SOH VC0 Command Reject' }, 'SBE Err Cnt': { 'dbData': telemetryDictionary['SBE Cnt'], 'fname': 'Single Bit Error Count' }, 'TMR Err Cnt': { 'dbData': telemetryDictionary['TMR Cnt'], 'fname': 'Flash TMR Errors Count' }, "UL Rand Status": { 'dbData': telemetryDictionary['Moab Uplink Rand Status'], 'fname': '' }, "DL Rand Status": { 'dbData': telemetryDictionary['Moab Downlink Rand Status'], 'fname': '' }, 'WD ISR Status': { 'dbData': telemetryDictionary['SMOAB WatchDog ISR Task Status'], 'fname': '' }, 'WD MBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog MBE Task Status'], 'fname': '' }, 'WD SBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog SBE Task Status'], 'fname': '' }, 'WD TLM Status': { 'dbData': telemetryDictionary['SMOAB WatchDog TLM Task Status'], 'fname': '' } } } },
      { id: 'SSMB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 500 }, data: { name: 'Solids State Memory Board(SSMB)', className: 'ssmbContainer', value: { 'SCI Read St': { 'dbData': telemetryDictionary['SSR Read Status'], 'fname': 'SCI current read State' }, 'SCI Pbk Time': { 'dbData': telemetryDictionary['SCI Pbk Time'], 'fname': '' }, 'SSOH Read St': { 'dbData': telemetryDictionary['SSOH Read State'], 'fname': 'SSR current read State' }, 'SSOH Pbk Time': { 'dbData': telemetryDictionary['SSOH Pbk Time'], 'fname': '' }, 'WD Status': { 'dbData': telemetryDictionary['SSMB WatchDog Task Status'], 'fname': '' } } } },
      { id: 'RAD750', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 50 }, data: { name: 'Single Board Computer(SBC)', value: { 'FSW Apt Cnt': { 'dbData': telemetryDictionary['FSW Apt Cnt'], 'fname': '' }, 'FSW Rj Cnt': { 'dbData': telemetryDictionary['FSW Rj Cnt'], 'fname': '' }, 'CBM Active': { 'dbData': telemetryDictionary['CBM Active'], 'fname': '' }, 'CSM Status': { 'dbData': telemetryDictionary['CSM Status'], 'fname': '' } } } },

      { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 0, y: -175 }, data: { name: '', playBackValue: { time: pbTimeRef.current, loop: pbLoopRef.current }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4'] } },

    ]
  }, [source]);


  useEffect(() => {
    if (!guiValues.current.pbisInPlayBack) {
      return;
    }

    let isCancelled = false;

    const processData = async () => {
      // Initialize an empty object to store telemetry data
      let aggregatedData: TelemetryDictionary = {};
      const totalLoops = Object.keys(influxData).length;

      for (let i = 0; i < totalLoops; i++) {
        // Check if the process should stop
        if (!guiValues.current.pbisInPlayBack || isCancelled) {
          break;
        }

        const key = Object.keys(influxData)[i];
        const selectedData = influxData[key];
        pbTimeRef.current = key;
        const newValue = (i + 1) / totalLoops;
        pbLoopRef.current = newValue;

        // Loop through each item in selectedData and update the aggregatedData object
        selectedData.forEach(item => {
          // If the key already exists, update the telemetry value
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: "",
              unit: "",
              spacecraft: "",
              live: "rgb(68, 169, 241)",
            };
          }
        });

        // Create nodes from the aggregatedData object
        const newNodes = createNodes(aggregatedData);
        setNodes(newNodes);

        // Log the current loop progress
        console.log(`${i + 1}/${totalLoops}`);

        // Wait for 2 seconds before processing the next item
        await new Promise(resolve => setTimeout(resolve, 2000));

      }
      console.log('Playback completed');
      guiValues.current.pbstopPlay()
    };

    processData();

    // Cleanup function to cancel the loop if pbisInPlayBack changes
    return () => {
      isCancelled = true;
    };
  }, [createNodes, influxData, guiValues]);

  useEffect(() => {
    //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
    if (guiValues.current.pbisInPlayBack) {
      return;
    }
    const telemetryDictionary1 = createTelemetryDictionary();

    const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
    setNodes(newNodes);
  }, [createNodes, createTelemetryDictionary, guiValues]);  

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = { type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15 };

  const initialEdges = [
    { id: 'edge1', source: 'SMOAB', sourceHandle: 'source-right-1', target: 'S-Band Transponder', targetHandle: 'target-left-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'TtoG', source: 'Tele Subsystem', sourceHandle: 'source-right-1', target: 'Ground Subsystem', targetHandle: 'target-right-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge2', source: 'Ground Subsystem', sourceHandle: 'source-left-1', target: 'MOC', targetHandle: 'target-right-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },

    { id: 'edge3', source: 'MOC', sourceHandle: 'source-right-1', target: 'Ground Subsystem', targetHandle: 'target-left-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'GtoT', source: 'Ground Subsystem', sourceHandle: 'source-right', target: 'Tele Subsystem', targetHandle: 'target-right-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge4', source: 'S-Band Transponder', sourceHandle: 'source-left-1', target: 'SMOAB', targetHandle: 'target-right-2', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge5', source: 'S-Band Transponder', sourceHandle: 'source-right-1', target: 'ScSketch', targetHandle: 'target-left-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge6', source: 'ScSketch', sourceHandle: 'source-left-1', target: 'S-Band Transponder', targetHandle: 'target-right-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge7', source: 'RAD750', sourceHandle: 'source-right', target: 'SMOAB', targetHandle: 'target-left-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge8', source: 'SSMB', sourceHandle: 'source-right', target: 'SMOAB', targetHandle: 'target-left-2', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge9', source: 'SMOAB', sourceHandle: 'source-left-1', target: 'RAD750', targetHandle: 'target-right-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },

  ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          onInit={onInit}
          nodes={nodes}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>
    </ReactFlowProvider>
  );

}

export default GlideCommApp;







