import React from "react";
import { InnerData} from "./utils";
import { css } from "@emotion/css";
import * as scheme from './Scheme'; // Import your styles

interface AntennasProp {
  data: {
    value: InnerData;
    name: string;
    info: {
      [key: string]: string;
    }
    linfo: {
      [key: string]: string;
    }
  };
}

const Antennas: React.FC<AntennasProp> = ({ data }) => {

  const groundStationStyle = css`
  display: flex;
  flex-direction: row;
  width: 950px;
  height: 450px;
  justify-content: space-between;
  align-items: center;
`;

const locationValueContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
`;

const groundValueContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 450px;
`;

const groundSvgValueContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 300px;

  & div:nth-of-type(3),& div:nth-of-type(4) {
    width: 500px;

    & span:nth-of-type(1) {
      width: 20%;
    }
  }
`;


  const center = { x: 200, y: 200 }; // Center of the circle
  const radius = 160; // Radius where the spacecraft should be placed

  const azimuthDeg = data.linfo['Azimuth'] ? parseFloat(data.linfo['Azimuth']) : 0; // Assume this is a number in degrees
  const elevationDeg = parseFloat(data.linfo['Elevation']);

  function calculate2DPosition(azimuth: number, elevation: number, centerX: number, centerY: number, radius: number, innerRadius: number) {
    // Convert degrees to radians
    function degToRad(degrees: number) {
        return degrees * Math.PI / 180;
    }

    // Convert azimuth and elevation to radians
    const adjustedAzimuth = (450 - azimuth) % 360;
    const azimuthRad = degToRad(adjustedAzimuth);
    
    let radialDistance;
    if (elevation <= 30) {
        // Interpolate between red circle (horizon) and yellow circle (30°)
        radialDistance = radius - ((radius - innerRadius) * (elevation / 30));
    } else if (elevation <= 90) {
        // Interpolate between yellow circle and green circle (center)
        radialDistance = innerRadius * ((90 - elevation) / 60);
    } else if (elevation <= 150) {
        // Interpolate between green circle (center) and yellow circle
        radialDistance = innerRadius * ((elevation - 90) / 60);
    } else if (elevation <= 180) {
        // Interpolate between yellow circle and red circle (horizon)
        radialDistance = radius - ((radius - innerRadius) * ((180 - elevation) / 30));
    } else {
        // Beyond 180°, gradually move away from red circle (horizon)
        radialDistance = radius + ((200 - radius) * ((elevation - 180) / 180));
    }

    // Calculate 2D coordinates
    const x2D = centerX + radialDistance * Math.cos(azimuthRad);
    const y2D = centerY - radialDistance * Math.sin(azimuthRad); // y-axis is positive downwards in SVG

    return { x: x2D, y: y2D };
  }

  const spacecraftPosition = calculate2DPosition(azimuthDeg,elevationDeg, center.x, center.y, radius, 110);

  return (
    <div className={groundStationStyle}>
      
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" version="1.1" viewBox="0 0 400 400">
          <rect width="400" height="400" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"/>
          <circle cx="200" cy="200" r="160" fill="rgb(38,38,38)" stroke="red" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"/>
          <circle cx="200" cy="200" r="110" fill="rgb(38,38,38)" stroke="yellow" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"/>
          <circle cx="200" cy="200" r="30" fill="rgb(38,38,38)" stroke="green" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"/>
          <rect x="170" y="197" width="60" height="6" fill="green"/>
          <rect x="197" y="170" width="6" height="60" fill="green"/>

          <g className='spacecraft'>
            <rect x={spacecraftPosition.x - 30} y={spacecraftPosition.y - 3} width="60" height="6" fill="white"/>
            <rect x={spacecraftPosition.x - 3} y={spacecraftPosition.y - 30} width="6" height="60" fill="white"/>
          </g>
        </svg>
      </div>
      
      <div className={groundValueContainerStyle}>
        <div className={locationValueContainerStyle}>
          <div className={scheme.svgValueSpanContainer}>
            <span className={scheme.svgKey}>Az</span>
            <span className={scheme.svgValue}>{azimuthDeg}</span>
          </div>
          <div className={scheme.svgValueSpanContainer}>
            <span className={scheme.svgKey}>El</span>
            <span className={scheme.svgValue}>{data.linfo['Elevation']}</span>
          </div>
        </div>

        <div className={groundSvgValueContainerStyle}>
            {data.info && Object.entries(data.info).map(([key, innerData]) => (
                <div className={scheme.svgValueSpanContainer} key={key}>
                  <span className={scheme.svgKey}>{key}</span>
                  <span className={scheme.svgValue}>{innerData}</span>
                </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Antennas;
