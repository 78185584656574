import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { PanelDataErrorView } from '@grafana/runtime';
import { SimpleOptions } from 'types';
import App from './App';
import * as dat from 'lil-gui';
import { css, cx } from '@emotion/css';
import { Field, LocationData, source } from './utils';
import { TimeRangeContext } from 'hooks/TimeRangeContext';
import { DataProvider, useData } from 'hooks/DataContext';
import { usePlayBack } from './playback';
import { fetchDataSch, fetchInfluxDB } from 'hooks/DataFetching';

interface Props extends PanelProps<SimpleOptions> {}

interface ResultField {
  name: string;
  values: number[];
}

interface ResultItem {
  fields: ResultField[];
}

export const PanelContent: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {

  const exampleFields: Field[] = [
    { name: "", type: "", values: []}
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;
  const limitData = data.series[1] ? data.series[1].fields : exampleFields;


  const [groundData, setGroundData] = useState<Field[]>([]);
  const [scData, setScData] = useState<LocationData>({ Azimuth: 0, Elevation: 0 });
  const { processedData, setProcessedData } = useData();  // Use the useData hook
  const {guiValues} = usePlayBack();

  useEffect(() => {
    const exQuery = `SELECT station, scName, tAos, tLos, trCode 
      FROM bdps.operational_schedule 
      WHERE scName LIKE '${source}' AND tLos > UNIX_TIMESTAMP()
      ORDER BY tLos ASC 
      LIMIT 1;`;

    const influxQuery1 = `from(bucket: "fd_30_days")
    |> range(start: -15d)
    |> filter(fn: (r) => r._measurement == "ESCAPADE")
    |> filter(fn: (r) => r.Spacecraft == "${source}")
    |> filter(fn: (r) => r.Station == "DSS 34")
    |> last()`;

    fetchDataSch(exQuery, 'MySQL - BTAPS - CVT').then(result => {
      setGroundData(result);
    });

    fetchInfluxDB(influxQuery1, 'INFLUX').then(result => {
      const azColumn = result.filter((litem: ResultItem) => litem.fields[1]?.name === "Azimuth [deg]");
      const elColumn = result.filter((litem: ResultItem) => litem.fields[1]?.name === "Elevation [deg]");
  
      const azValue = azColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;
      const elValue = elColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;

      const locationDict: LocationData = { Azimuth: parseFloat(azValue), Elevation: parseFloat(elValue) };

      setScData(locationDict);
    });
  }, []); // Effect will re-run if diffSouceName changes
  
  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();

      //gui.add(guiValues, 'screenSpacePanning').name('Space Panning');

      const f1 = gui.addFolder('Query');
      //f1.add(guiValues, 'startDateTime').name('Start Date Time').listen();
      //f1.add(guiValues, 'endDateTime').name('End Date Time').listen();

      const aggController = f1.add(guiValues.current, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues.current, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.current.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      aggFnController.disable();
      aggTimeController.disable();

      const lastController = f1.add(guiValues.current, 'Last').name('Last');
      const lastCountController = f1.add(guiValues.current, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();

      aggController.onChange((value: boolean) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
      });

      lastController.onChange((value: boolean) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
      });

      const f2 = gui.addFolder('Playback');
      f2.add(guiValues.current, 'startDateTime').name('Start Date Time');
      f2.add(guiValues.current, 'endDateTime').name('End Date Time');
      f2.add(guiValues.current, 'pbaggTime').name('Agg Time');
      f2.add(guiValues.current.pbaggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      //f1.add( guiValues, 'loadingPercent', 0,100, 1).name('Loading %').disable();
      f2.add(guiValues.current, 'pbplayBack').name('Playback');
      f2.add(guiValues.current, 'pbstopPlay').name('Stop Playback');

      f1.close();
      f2.close();
      if (!guiValues.current.pbisInPlayBack) {
        setProcessedData({});
      }

    }
  }, [guiValues, setProcessedData]);

      // Extract the time range as sa
      const timeRange = {
        from: data.timeRange.from,
        to: data.timeRange.to
      };

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      css`
        width: ${width}px;
        height: ${height}px;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )}>

      <TimeRangeContext.Provider value={{ timeRange, guiValues }}>
      <div ref={guiContainerRef} />
      <App dbData={selectedData} width={width} height={height} groundData={groundData} locationData={scData} source={source} influxData={processedData} limitData={limitData}></App>
      </TimeRangeContext.Provider>
    </div>
  );
};

export const SimplePanel: React.FC<Props> = (props) => {
  return (
    <DataProvider>
      <PanelContent {...props} />
    </DataProvider>
  );
};