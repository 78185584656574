import { useCallback } from "react";
import { DataObject, Field, ProcessedData, TelemetryData, TelemetryDictionary, decodeFunc, determineLimit, lookUpName, redisDataType, source } from "../components/utils";

function isWithinDifference(unixTimestamp: number) {

  // Convert the Unix timestamp to a JavaScript Date object
  const timestampDate = new Date(unixTimestamp * 1000);

  // Get the current time
  const currentDate = new Date();

  // Calculate the difference in seconds
  const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

  // Compare and return
  if (timeDifference <= 30) {
      return "rgb(72, 200, 44)";
  } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
      return "#CA51EC";
  } else {
      return "rgb(68, 169, 241)"; //blue -- stale data
  }
}

function convertTimestampToPST(timestamp: number) {
  // Create a new Date object using the timestamp (in milliseconds)
  const date = new Date(timestamp * 1000);

  // To convert to PST, get the UTC time and subtract 8 hours for PST
  // Note: JavaScript Date object functions account for Daylight Saving Time changes
  const pstDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours() - 8, date.getUTCMinutes(), date.getUTCSeconds());

  // Format the date and time in a readable format
  const formattedDate = pstDate.getFullYear() + '-' +
    ('0' + (pstDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + pstDate.getDate()).slice(-2) + ' ' +
    ('0' + pstDate.getHours()).slice(-2) + ':' +
    ('0' + pstDate.getMinutes()).slice(-2) + ':' +
    ('0' + pstDate.getSeconds()).slice(-2);

  return formattedDate;
}

// Custom hook to use telemetry data
export const useTelemetryData = (dbData: Field[], limitData: Field[]) => {

  const processRedisData = useCallback(() => {

    const telemetryDict: Record<string, TelemetryData> = {};

    const valueColumn = dbData.find(column => column.name === "value");
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    
    if (!valueColumn) {
      return {}; // mne column not found
    }

    const valueValue = valueColumn && valueColumn.values ? valueColumn.values : [];

    valueValue.map(value => {
      const decodeValue = decodeFunc(value) as redisDataType

      const mne = decodeValue.mne;
      const limitMneIndex = limitMneColumn && limitMneColumn.values.indexOf(mne);

      
      let limitValue;
      let cnvValue = decodeValue.cnv;

      if (limitMneIndex === -1 || !limitMneIndex) {
        limitValue = ''
      }
      else {

        const ylValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null;
        const yHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null;
        const rlValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null;
        const rHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        limitValue = determineLimit(cnvValue, {
          yl: ylValue,
          yh: yHValue,
          rl: rlValue,
          rh: rHValue
        })
      }

      const valueName = lookUpName(mne);

      // Special handling for 'WFI HV' and 'NFI HV'
      if (mne === 'tcs_spoc_rtd_n2_bottle_probe_1_t' || mne === 'tcs_spoc_rtd_n2_bottle_probe_2_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_3_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_4_t') {
        cnvValue = parseFloat(cnvValue).toExponential(2);
      } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
        // Check if cnvValue is a number and convert/round it if s
        const numValue = parseFloat(cnvValue);
        const [integerPart, decimalPart] = numValue.toString().split('.');

        // Convert to exponential form if the integer part is too long
          if (integerPart.length > 7) {
        cnvValue = numValue.toExponential(2);
          } else if (decimalPart) {
            // It's a float, parse and keep 2 decimal places
            cnvValue = numValue.toFixed(2);
          }
      }

      telemetryDict[valueName] = {
        limit: limitValue,
        mne: mne,
        telemetry: cnvValue,
        live: isWithinDifference(decodeValue.t_insert),
        spacecraft: decodeValue.facility,
        unit: ""
      };
    });

    return telemetryDict;
  },[dbData]);

  const editRedisDataLimit = useCallback((data: TelemetryDictionary) => {
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    const limitSwitchMneColumn = limitData.find(column => column.name === "switchMne");
    const limitSwitchLowerColumn = limitData.find(column => column.name === "switchLower");
    const limitSwitchUpperColumn = limitData.find(column => column.name === "switchUpper");

    const limitSwitchMneValue = limitSwitchMneColumn && limitSwitchMneColumn.values ? limitSwitchMneColumn.values : [];

    // if there is a switchMne coumn, then we need to use this set of limit. Therefore we will replace the old limit value
    // with the new limit value 
    limitSwitchMneValue.map(switchMneValue => {
      
      const limitMneIndex = limitSwitchMneColumn && limitSwitchMneColumn.values.indexOf(switchMneValue);

      if (limitMneIndex) {
        
        const limitSwitchLowerValue = limitSwitchLowerColumn ? limitSwitchLowerColumn.values[limitMneIndex] : "";
        const limitSwitchUpperValue = limitSwitchUpperColumn ? limitSwitchUpperColumn.values[limitMneIndex] : "";
        const limitMneValue = limitMneColumn ? limitMneColumn.values[limitMneIndex] : null;
        const limitYLValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null
        const limitYHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null
        const limitRLValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null
        const limitRHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        const switchMneName = lookUpName(switchMneValue);
        const mneName = lookUpName(limitMneValue);

        const switchMneCnv = data[switchMneName].telemetry;
        const mneValue = data[mneName].telemetry;

        if (limitSwitchLowerValue <= switchMneCnv <= limitSwitchUpperValue){

          const limit = determineLimit(mneValue, {
            yl: limitYLValue,
            yh: limitYHValue,
            rl: limitRLValue,
            rh: limitRHValue
          });

          data[mneName].limit = limit;

        }

      }

    });

    return data;
  }, []);

  const getMySQLFromSource = useCallback(() => {

    const telemetryDict: Record<string, TelemetryData> = {};

    const mneColumn = dbData.find(column => column.name === "mne");

    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");

    const mneIndex = mneColumn && mneColumn.values ? mneColumn.values : [];

    mneIndex.map((mne, index) => {
      const limit = limitColumn ? limitColumn.values[index] : null;
      let cnvValue = cnvValueColumn ? cnvValueColumn.values[index] : null;
      let unit = unitColumn ? unitColumn.values[index] : null;
      let live = timeColumn ? isWithinDifference(timeColumn.values[index]) : "";

      // Append the degree symbol to the unit if the unit is 'C'
      if (unit === 'C' || unit === 'degC') {
        unit = '\u00B0C';
      } else if (unit === 'packets') {
        unit = 'P';
      } else if (unit === 'sec') {
        unit = 'S';
      } else if (unit === 'None' || unit === 'enum' || unit === 'N/A') {
        unit = null;
      } else if (unit === 'bool') {
        unit = 'B';
      } else if (unit === 'count' || unit === 'counts' || unit === 'Counter') {
        unit = 'CNT';
      } else if (unit === 'volts') {
        unit = 'V';
      } else if (unit === 'amps') {
        unit = 'A';
      }

      // Special handling for specific mnemonics
      if (mne === 'cdh_supervisor_uptime_agg' || mne === 'CDH_SPOC_UPTIME_agg'.toLowerCase()) {
        cnvValue = (parseFloat(cnvValue) / 1000).toFixed(2);
        unit = 's';
      } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
        const numValue = parseFloat(cnvValue);
        const [integerPart, decimalPart] = numValue.toString().split('.');

        // Convert to exponential form if the integer part is too long
        if (integerPart.length > 7) {
          cnvValue = numValue.toExponential(2);
        } else if (decimalPart) {
          cnvValue = numValue.toFixed(2);
        }
      }
      
      const valueName = lookUpName(mne);

      telemetryDict[valueName] = {
        limit: limit,
        mne: mne,
        telemetry: cnvValue,
        unit: unit,
        live: live,
        spacecraft: source ? source : ""
      };

    });

    return telemetryDict;
  }, [dbData]);

  const createTelemetryDictionary = useCallback(() => {
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const valueColumn = dbData.find(column => column.name === "value");

    if (valueColumn) {
      const redisData = processRedisData();
      const redisDataWithLimit = editRedisDataLimit(redisData);
      return redisDataWithLimit;
    } else if (cnvValueColumn) {
      const sqlData = getMySQLFromSource();
      return sqlData;
    } else {
      return {};
    }
  }, [dbData, editRedisDataLimit, getMySQLFromSource, processRedisData]);


  return { createTelemetryDictionary };
};



// Custom hook to use telemetry data
export const useGroundData = (groundData: Field[]) => {

  const getGroundData = useCallback(() => {
    // Safely get the other columns
    const stationColumn = groundData.find(column => column.name === "station");
    const scColumn = groundData.find(column => column.name === "scName");
    const tAosColumn = groundData.find(column => column.name === "tAos");
    const tLosColumn = groundData.find(column => column.name === "tLos");
    const trCodeColumn = groundData.find(column => column.name === "trCode");

    const station = stationColumn ? stationColumn.values[0] : null;
    let scName = scColumn ? scColumn.values[0] : null;
    let tAos = tAosColumn ? tAosColumn.values[0] : null;
    let tLos = tLosColumn ? tLosColumn.values[0] : null;
    let trCode = trCodeColumn ? trCodeColumn.values[0] : null;

    return { 'SC': scName, 'GS': station, 'AOS': convertTimestampToPST(tAos), 'LOS': convertTimestampToPST(tLos), 'Tr': trCode };
  }, [groundData]);

  return { getGroundData };
};

export function processInfluxPlayBackData(dataArray: DataObject[]): ProcessedData {
      const result: ProcessedData = {};
    
      dataArray.forEach(dataObject => {
        const timeField = dataObject.fields.find(field => field.name.toLowerCase() === "_time");
        const cnvValueField = dataObject.fields.find(field => field.name.toLowerCase() === "cnv_value");
        const tpktField = dataObject.fields.find(field => field.name.toLowerCase() === "t_pkt");
        const tInsertField = dataObject.fields.find(field => field.name.toLowerCase() === "t_insert");
  
        if (timeField && cnvValueField && tpktField && tInsertField) {
  
          timeField.values.forEach((timeValue, index) => {
            const cnvValue =  cnvValueField.values[index] === null ? "" : cnvValueField.values[index].toFixed(2);
    
            const mne = cnvValueField && cnvValueField.labels ? cnvValueField.labels.mne : "";
    
            if (!result[timeValue]) {
              result[timeValue] = [];
            }
  
            const name = lookUpName(mne);
    
            result[timeValue].push({ cnvValue, mne, name });
          });
        }
      });
    
      return result;
}

