import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import { InnerData, TLM, determineColorNew, handleCopyMneName } from './utils';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import PlotlyChart from './PlotlyChart';
import * as sbScheme from './SbScheme'; // Import your style

interface GlideCommSBTProps {
  data: {
    value: InnerData;
    name: string;
    power: TLM;
    power1: TLM;
    className: string;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const GlideCommSBT: React.FC<GlideCommSBTProps> = ({data}) => {

  const [showPowerInfo1, setShowPowerInfo1] = useState(false);
  // const [showStateInfo, setShowStateInfo] = useState(false);

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => {setShowPowerInfo1(false)}, 1000);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const getColor = (power: string) => {
    if (power === "ON") {
        return "rgb(13, 84, 7)"; // Green for the selected and ON disk
    } else if (power === "OFF") {
        return "rgb(100,100,100)"; // Default gray color
    }
    else{
      return "rgb(100,100,100)";
    }
  }

  const sbContainer = css`
  width: 1800px;
  height: 700px;
  // border: 5px dashed rgb(128, 128, 255);
  // background-color: rgb(128, 128, 255);
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
  `
  
  return (
    <div className={cx(sbContainer)}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:195}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:350}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}} />
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top: 300}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top: 195}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"style={{opacity:0}}/>

      <div className={cx(sbScheme[data.className as sbScheme.ClassKey])}>


        <p>S-Band Transponder</p>

        {data.power && <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '50px',
          }}>
          <svg width="50px" height="50">
            <g>
            <ellipse cx="25" cy="25" rx="15" ry="15" fill={getColor(data.power.telemetry)} strokeWidth="6" stroke="black" pointerEvents="all" onClick={handlePowerEllipseClick1}/>
              </g>
              </svg>
              </div>}

            {data.power1 && <div style={{
            position: 'absolute',
            top: '0',
            right: '0',
            width: '50px',
          }}>
              <svg width="50px" height="50">
            <g>
            <ellipse cx="25" cy="25" rx="15" ry="15" fill={getColor(data.power1.telemetry)} strokeWidth="6" stroke="black" pointerEvents="all" onClick={handlePowerEllipseClick1}/>
              </g>
              </svg>
              </div>}

        <div className={scheme.tbHorContainer}>

        {data.value && Object.entries(data.value).map(([key, innerData]) => {
      const isLockAndLocked = key.toLowerCase().includes('lock') && innerData.dbData?.telemetry === 'LOCK';
      const isdetectAndDetected = key.toLowerCase().includes('detect') && innerData.dbData?.telemetry === 'DETECT';
    return (
      <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
        <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
          <span 
            className={cx(
              scheme.value, 
              css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
              scheme.classMap[determineColorNew(innerData.dbData?.limit)],
              css`background-color: ${isLockAndLocked ? 'rgb(13, 84, 7)' : ''};`,
              css`background-color: ${isdetectAndDetected ? 'rgb(13, 84, 7)' : ''};`
            )}
          >
            {innerData.dbData?.telemetry}
          </span>
          {innerData.dbData && innerData.dbData.unit && (
            <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
          )}
        </div>
        {visibleLimitPops[key] && (
          <div className={scheme.chartContainer}>
            <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
            <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
              Close Plot
            </button>
          </div>
        )}
      </div>
    );
  })}
          </div>
        </div>
    </div>
  )
}

export default GlideCommSBT;
