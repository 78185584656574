import { css } from '@emotion/css';

export const sbContainer = css`
width: 1920px;

  & > div {
    width:1920px;

    & > div {

      &:nth-of-type(1),
      &:nth-of-type(5), {
        width: 470px;

        & div:nth-of-type(2) {
          width: 150px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(6), {
        width: 450px;

        & div:nth-of-type(2) {
          width: 150px;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(7), {
        width: 445px;

        & div:nth-of-type(2) {
          width: 180px;
        }
      }

      &:nth-of-type(4),
      &:nth-of-type(8), {
        width: 415px;

        & div:nth-of-type(2) {
          width: 190px;
        }
      }

    }
  }
`;


// Define a type for the class keys
export type ClassKey = 'sbContainer'