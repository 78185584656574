import React, { useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { css, cx } from '@emotion/css';
import { TLM } from './utils';

interface PassProp {
  data: {
    sigDet: TLM;
    sigPwr: TLM;
    loopStress: TLM;
    carrierLock: TLM;
    detLock: TLM;
    bitLock: TLM;
    cmdDecode: TLM;
  };
}

const PassIndicator: React.FC<PassProp> = ({ data }) => {

  const [cmdDecodeFill, setCmdDecodeFill] = useState('gray');
  const prevCmdDecodeValue = useRef<number | null>(null);
  const lastUpdateTime = useRef<number>(Date.now());

  // Track command decode changes
  useEffect(() => {
    if (data.cmdDecode) {
      const currentValue = parseInt(data.cmdDecode.telemetry);
      const currentTime = Date.now();
      
      if (prevCmdDecodeValue.current !== null) {
        // Check if value has increased and time difference is within 5 seconds
        if (currentValue > prevCmdDecodeValue.current && 
            (currentTime - lastUpdateTime.current) <= 30000) {
          setCmdDecodeFill('green');
        } else {
          setCmdDecodeFill('gray');
        }
      }
      
      prevCmdDecodeValue.current = currentValue;
      lastUpdateTime.current = currentTime;
    }
  }, [data.cmdDecode]);
  
  const sigDetFill = data.sigDet && data.sigDet.telemetry === 'NODETECT' ? 'gray' : 'green';
  const carrierLockFill = data.carrierLock && data.carrierLock.telemetry === 'NOLOCK' ? 'gray' : 'green';
  const detLockFill = data.detLock && data.detLock.telemetry === 'NOLOCK' ? 'gray' : 'green';
  const bitLockFill = data.bitLock && data.bitLock.telemetry === 'NOLOCK' ? 'gray' : 'green';

  let loopStressFill;

  if (data.loopStress) {
    const loopStressValue = parseFloat(data.loopStress.telemetry);
    if (loopStressValue === 0) {
      if (detLockFill === 'green') {
        loopStressFill = 'green';
      } else {
        loopStressFill = 'gray';
      }
    } else {
      loopStressFill = 'gray';
    }
  }

  const flowStyles = css`
    @keyframes flowDash {
      to {
        stroke-dashoffset: 60;
      }
    }
    .animated-line {
      stroke-dasharray: 30 30;
    }
    .animated-line.active {
      animation: flowDash 1s linear infinite;
    }
    .arrow-container {
      opacity: 0.7;
      transition: opacity 0.3s ease;
    }
    .arrow-container.active {
      opacity: 1;
    }
  `;

  const containerStyles = css`
    width: 100%;
  `;

  const rowStyles = css`
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
  `;

  const indicatorStyles = css`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      margin: 0 0 10px 0;
    }
  `;

  const allGreen = sigDetFill === 'green' && 
                   carrierLockFill === 'green' && 
                   detLockFill === 'green' && 
                   bitLockFill === 'green' && 
                   loopStressFill === 'green' &&
                   cmdDecodeFill === 'green';

  const indicators = [
    { name: 'Signal Detect', fill: sigDetFill },
    { name: 'Signal Lock', fill: detLockFill },
    { name: 'Loop Stress', fill: loopStressFill },
    { name: 'Carrier Lock', fill: carrierLockFill },
    { name: 'Bitsync Lock', fill: bitLockFill },
    { name: 'Command Decoded', fill: cmdDecodeFill }
  ];

  return (
    <div className={containerStyles}>
      {/* Handles */}
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      {/* Indicators */}
      <div className={rowStyles}>
        {indicators.map((indicator, index) => (
          <div key={indicator.name} className={indicatorStyles}>
            <p>{indicator.name}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="300px"
              height="100px"
              viewBox="-0.5 -0.5 300 100"
            >
              <g>
                <ellipse
                  cx="150"
                  cy="50"
                  rx="20"
                  ry="20"
                  fill={indicator.fill}
                  stroke="#FFFFFF"
                  strokeWidth="5"
                  pointerEvents="all"
                />
              </g>
            </svg>
          </div>
        ))}
      </div>

      {/* Arrows and Lines */}
      <div className={cx(flowStyles, rowStyles)}>
        {indicators.map((indicator, index) => (
          <div 
            key={`arrow-${index}`}
            className={cx('arrow-container', {
              'active': indicator.fill === 'green'
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="300px"
              height="50px"
              viewBox="-0.5 -0.5 300 50"
            >
              <g>
                <line 
                  x1="0" 
                  y1="25" 
                  x2="300" 
                  y2="25" 
                  stroke={indicator.fill} 
                  strokeWidth="5" 
                  className={cx('animated-line', {
                    'active': allGreen
                  })}
                />
                {index === indicators.length - 1 && ( // Only render arrow heads for the first item (last in visual order due to row-reverse)
                  <>
                    <line 
                      x1="0" 
                      y1="25" 
                      x2="40" 
                      y2="0" 
                      stroke={indicator.fill} 
                      strokeWidth="5"
                    />
                    <line 
                      x1="0" 
                      y1="25" 
                      x2="40" 
                      y2="50" 
                      stroke={indicator.fill} 
                      strokeWidth="5"
                    />
                  </>
                )}
              </g>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PassIndicator;