import { css } from '@emotion/css';

export const tcContainer = css`
  width: 600px;
`;

export const rxContainer = css`
  width: 520px;
`;

export const ssmbContainer = css`
  width: 520px;
`;


// Define a type for the class keyss
export type ClassKey = 'tcContainer' | 'rxContainer' | 'ssmbContainer';