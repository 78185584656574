import { DataSourceApi } from "@grafana/data";
import { getDataSourceSrv } from "@grafana/runtime";
import { Field } from "../components/utils";

export const fetchData = async (query: string, dataSourceName: string) => {
    // Example query incorporating a variable
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);

        // Execute the query and wait for the promise to resolve
        //@ts-ignore
      const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();

      return result;

  };

export const fetchDataSch = async (query: string, dataSourceName: string): Promise<Field[]> => {
    try {
      const dataSource = await getDataSourceSrv().get(dataSourceName);
      const result = await dataSource.query({
        // @ts-ignore
        targets: [{ refId: 'queryB', rawSql: query, format: 'table' }]}).toPromise();
      return result.data.length > 0 ? result.data[0].fields : [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

export const fetchInfluxDB = async (query: string, dataSourceName: string) => {
    try {
      const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);
      //@ts-ignore
      const result = await dataSource.query({ targets: [{ query, refId: 'comQ' }] }).toPromise();
      return result.data;
    } catch (error) {
      console.error('Error fetching InfluxDB data:', error);
      return [];
    }
  };

  