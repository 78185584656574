import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

const MOC: React.FC = () => {

  const mocContainer = css`
  width: 1200px;
  height: 700px;
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
  `

  return (
    <div className={mocContainer}>
      <span>Mission Operation Center </span>

      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:450}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top:300}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

    </div>
  )
}

export default MOC;
